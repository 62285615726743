/* 👇 Directly import the mantine core styles into mantine-core layer */
/* @import '@mantine/core/styles.css' layer(mantine-core); */

/* 👇 Directly import the mantine-contextmenu styles into mantine-contextmenu layer */
/* @import 'mantine-contextmenu/dist/styles.css' layer(mantine-contextmenu); */

/* 👇 Make sure the styles are applied in the correct order */

@layer mantine, mantine-contextmenu;

/* @layer defaults {
    a {
       color: mediumvioletred; 
    }
  } */